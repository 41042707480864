<template lang="html">
  <div v-if="isVisible" class="item-content">
    <Ticket
      v-for="ticket in tickets"
      :ticket="ticket"
      :key="ticket.id">
    </Ticket>
  </div>
</template>

<script>
  import Ticket from './Ticket'


  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },

      tickets: {
        type: Array,
        required: true
      },
    },

    components: {
      Ticket
    }
  }
</script>

<style lang="scss" scoped>
  .item-content {
    margin-bottom: 1rem;
  }
</style>
