import Cart from 'src/Models/Carts/Model'
import CartTicket from 'src/Models/CartTickets/Model'
import * as TYPES from '../../types'
import { unionBy } from 'lodash'

const parseAmountByLimits = function (cart_tickets, ticket, kind) {
  let amount = cart_tickets.filter((cartTicket) => {
    if (cartTicket.ticket_id == ticket.id) { return cartTicket }
  })
  let count_amount = kind == 'add' ? amount.length + 1 : amount.length - 1
  // let count_amount = amount.length + 1

  let cloned_ticket = JSON.parse(JSON.stringify(ticket));
  let min_ticket = cloned_ticket.min_ticket;
  let max_ticket = cloned_ticket.max_ticket;

  // console.log("amountByTicket >", count_amount, min_ticket, max_ticket)

  if (!min_ticket) {
    return 1;
  }

  if (min_ticket && !max_ticket && count_amount >= min_ticket && kind == 'add') {
    return 1;
  }

  if (min_ticket == max_ticket && count_amount >= min_ticket) {
    return null;
  }

  if (min_ticket && max_ticket && count_amount <= max_ticket) {
    count_amount = count_amount >= min_ticket && count_amount <= max_ticket ? 1 : min_ticket;
  } else if(min_ticket && !max_ticket && count_amount != 0) {
    count_amount = count_amount >= min_ticket ? count_amount : (kind != 'add' ? 0 : min_ticket);
  } else if(!min_ticket && max_ticket && count_amount != 0) {
    count_amount = count_amount <= max_ticket ? count_amount : max_ticket;
  } else {
    return null;
  }

  return count_amount
}

export default {
  [TYPES.SET_CURRENT_CART](state, cart) {
    state.current = new Cart(cart)
  },

  [TYPES.CLEAN_CART_TICKETS_FROM_CURRENT_CART](state) {
    state.current.cart_tickets_attributes = []
  },

  [TYPES.SET_MAX_PURCHASE_TO_CURRENT_CART](state, promotion) {
    if (promotion.amount_remaining > 0 && promotion.amount_remaining < 6) {
      state.current.maxPurchase = promotion.amount_remaining
    }
  },

  [TYPES.NORMALIZE_CURRENT_CART](state, ticketsOpened) {
    const ticketsOpenedIds = ticketsOpened.map(ticket => ticket.id)
    const cartTickets =
      state.current.cart_tickets_attributes.filter(cartTicket => {
        return ticketsOpenedIds.includes(cartTicket.ticket_id)
      })

    state.current.cart_tickets_attributes = cartTickets
  },

  [TYPES.ADD_CART_TICKET_TO_CURRENT_CART](state, ticket) {
    const current_tickets = state.current.cart_tickets_attributes.filter((cartTicket) => cartTicket.ticket_id == ticket.id);
    const count_amount = parseAmountByLimits(current_tickets, ticket, 'add')

    if (!count_amount) return;

    const cart_tickets = [...Array(count_amount).keys()].map(() => {
      return new CartTicket({
        ticket_id: ticket.id,
        ticket_name: ticket.name,
        price_total: ticket.priceWithDiscount,
        fee_amount: ticket.taxPrice,
        promotion_id: ticket.promotionId,
      })
    })

    cart_tickets.map(function(cart_ticket) {
      state.current.cart_tickets_attributes =
        [...state.current.cart_tickets_attributes, cart_ticket]
    })

    // const cartTicket = new CartTicket({
    //   ticket_id: ticket.id,
    //   price_total: ticket.priceWithDiscount,
    //   fee_amount: ticket.taxPrice,
    //   promotion_id: ticket.promotionId,
    // })

    // state.current.cart_tickets_attributes =
    //   [...state.current.cart_tickets_attributes, cartTicket]
  },

  [TYPES.REMOVE_CART_TICKET_TO_CURRENT_CART](state, ticket) {
    let current_tickets = state.current.cart_tickets_attributes.filter((cartTicket) => cartTicket.ticket_id == ticket.id);
    let remain_tickets = state.current.cart_tickets_attributes.filter((cartTicket) => cartTicket.ticket_id != ticket.id);
    let count_amount = parseAmountByLimits(current_tickets, ticket, 'minus')
    let cloned_ticket = JSON.parse(JSON.stringify(ticket));
    let min_ticket = cloned_ticket.min_ticket;
    let max_ticket = cloned_ticket.max_ticket;

    // console.log("count_amount" , count_amount, current_tickets.length, min_ticket, max_ticket)

    if (min_ticket && max_ticket && min_ticket != max_ticket) {
      if (count_amount >= min_ticket) {
        current_tickets = current_tickets.splice(0, 0)
      } else {
        current_tickets = current_tickets.splice(0, current_tickets.length - 1)
      }
    } else if(min_ticket && !max_ticket) {
      if (count_amount < min_ticket) {
        current_tickets = current_tickets.splice(0, 0)
      } else {
        current_tickets = current_tickets.splice(0, current_tickets.length - 1)
      }
    } else if(!min_ticket && max_ticket) {
      current_tickets = current_tickets.splice(0, current_tickets.length - 1)
    } else {
      // current_tickets = current_tickets.splice(0, current_tickets.length - 1)
      current_tickets = current_tickets.splice(0, 0)
    }

    state.current.cart_tickets_attributes = [...remain_tickets, ...current_tickets]

    // const index =
    //   state.current.cart_tickets_attributes
    //     .findIndex((cartTicket) => cartTicket.ticket_id == ticket.id)

    // if (index >= 0) {
    //   state.current.cart_tickets_attributes.splice(index, 1)
    // }
  },
}
