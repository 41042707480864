<template lang="html">
  <div class="modal" :class="classModal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button @click="onClose" class="delete"></button>
      </header>

      <section class="modal-card-body">
        <slot></slot>
      </section>

      <footer v-show="showFooter" class="modal-card-foot">
        <a @click="onClose">{{ cancelButtonText }}</a>
        <a
          class="button is-primary is-medium"
          :class="classLoading"
          :disabled="isFetching"
          @click="onSave">
          {{ saveButtonText }}
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.esm'

export default {
  name: 'modal',

  props: {
    title: {
      type: String,
      required: true
    },

    isVisible: {
      type: Boolean,
      required: true,
    },

    closeModal: {
      type: Function,
      required: true
    },

    saveButton: {
      type: Function
    },

    saveButtonText: {
      type: String,
      default: "Salvar"
    },

    cancelButtonText: {
      type: String,
      default: "Cancelar"
    },

    showFooter: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object
    }
  },

  computed: {
    ...mapGetters([
      'isFetching',
    ]),

    classModal: function () {
      return {
        "is-active": this.isVisible
      }
    },

    classLoading () {
      return {
        "is-loading": this.isFetching
      }
    }
  },

  mounted: function () {
    document.addEventListener("keydown", (e) => {
      if (this.isVisible && e.keyCode == 27) {
        this.closeModal()
      }
    })
  },

  methods: {
    onSave() {
      this.saveButton()
    },

    onClose() {
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card-head {
    background-color: #357ede;
  }

  .modal-card-title {
    margin-bottom: 0 !important;
    color: #FFFFFF;
  }

  .modal-card-foot {
    justify-content: flex-end !important;

    > .button {
      margin-left: 1rem;
    }
  }

  // RESPONSIVE
  @media(max-width: 700px){
    .modal {
      z-index: 200;
      align-items: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .modal-card {
      max-height: 100vh;
      margin: 0px;
    }
  }
</style>
