<template lang="html">
  <Loading :isLoading="isLoading" :classes="{'-loading': isLoading}">
    <div v-if="!isLoading" class="shopping-cart">
      <div
        class="item-container"
        :class="{'-fetching': isFetching}"
        v-for="(sector, index) in sectors">
        <SCHeader :sector="sector" :index="index"></SCHeader>

        <SCContent
          :isVisible="sector.isVisible"
          :tickets="sector.tickets">
        </SCContent>
      </div>

      <div class="shopping-cart-actions">
        <SCPromotion :promotionCode="promotion_code"></SCPromotion>

        <SCButton
          :cartTotal="cart.priceTotalCurrency"
          :buttonDisabled="buttonDisabled"
          :isFetching="isFetching"
          :textBuyer="textBuyer"
          :createCart="handleSubmit">
        </SCButton>
      </div>
    </div>
  </Loading>
</template>

<script>
  import { isEmpty } from 'lodash'
  import { mapActions, mapGetters } from 'vuex/dist/vuex.esm'
  import Cart from 'src/Models/Carts/Model'
  import Loading from 'src/Shared/Loading'
  import SCHeader from './Container/Header'
  import SCContent from './Container/Content'
  import SCButton from './Container/Button'
  import SCPromotion from './Container/Promotion'


  export default {
    name: 'shopping-cart',


    data() {
      return {
        isLoading: true,
      }
    },

    props: {
      event_id: {
        type: String,
        required: true
      },

      tax: {
        type: String,
        required: true
      },

      customer_fee: {
        type: String,
        required: true
      },

      promotion_code: {
        type: String,
        default: ""
      },

      textBuyer: {
        type: String,
        required: true
      },
    },


    mounted() {
      this.initCart({
        event_id: this.event_id,
        eventTax: parseFloat(this.tax),
        customerFee: JSON.parse(this.customer_fee),
        maxPurchase: 1000,
      })
      .then(() => this.handleFetchSectors())
      .catch(error => console.warn(error))
    },


    components: {
      Loading,
      SCHeader,
      SCContent,
      SCButton,
      SCPromotion,
    },


    computed: {
      ...mapGetters({
        sectors: 'allSectors',
        cart: 'currentCart',
        isFetching: 'isFetching',
      }),

      buttonDisabled: function() {
        return !this.cart.hasCartTickets || this.isFetching
      }
    },


    methods: {
      ...mapActions([
        'setFetching',
        'initCart',
        'fetchSectors',
        'createCart',
      ]),

      handleSubmit() {
        this.setFetching({ fetching: true })
        this.handleCreateCart().then((data) => {
          if (window.fbq) { window.fbq('track', 'InitiateCheckout') }

          window.location.href = '/eventos/inscricao'
        })
        .catch((error) => console.warn(error))
      },

      handleCreateCart() {
        const { cart } = this
        return this.createCart({ cart })
          .then((data) => Promise.resolve(data))
          .catch((error) => Promise.reject(error))
      },

      handleFetchSectors() {
        this.fetchSectors({
          eventId: this.event_id,
          eventTax: parseFloat(this.tax),
          customerFee: JSON.parse(this.customer_fee),
        }).then((data) => this.isLoading = false)
        .catch((error) => console.warn(error))
      },
    }
  }
</script>

<style lang="scss" scoped>
  .item-container {
    border-top: 1px solid #c2c2c2;

    &.-fetching {
      &:before {
        content: '';
        background-color: rgba(255,255,255,0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }

  .item-container:first-child {
    border-top: unset;
  }

  .shopping-cart-actions {
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;

    @media screen and (max-width: 768px) {
      & {
        justify-content: center;
      }
    }
  }
</style>
