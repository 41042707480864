import { toCurrency } from 'utils/currency'
import schema from './Schema'

export default class Ticket {
  constructor(data = {}, eventTax = 0, customerFee = true) {
    Object.assign(this, schema, data)
    this.eventTax = eventTax
    this.customerFee = customerFee
  }

  get fullName() {
    return `${this.sector_name} - ${this.nameByLot}`
  }

  get nameByLot() {
    return `${this.name} (Lote ${this.lot})`
  }

  get totalPrice() {
    if (this.customerFee) {
      return this.priceWithDiscount + this.taxPrice
    } else {
      return this.priceWithDiscount
    }
  }

  get totalPriceCurrency() {
    return toCurrency(this.totalPrice)
  }

  get priceOriginalCurrency() {
    if (this.price > 0) {
      return toCurrency(this.price)
    } else {
      return "Grátis"
    }
  }

  get priceWithDiscount() {
    const priceWithDiscount = this.price - this.priceDiscount

    if (priceWithDiscount > 0) {
      return priceWithDiscount
    } else {
      return 0
    }
  }

  get priceWithDiscountCurrency() {
    if (this.priceWithDiscount > 0) {
      return toCurrency(this.priceWithDiscount)
    } else {
      return "Grátis"
    }
  }

  get isFree() {
    if (this.price > 0) {
      return false
    } else {
      return true
    }
  }

  get isSoldout() {
    if (this.status == 'soldout') {
      return true
    } else {
      return false
    }
  }

  get isOpened() {
    if (this.status == 'opened') {
      return true
    } else {
      return false
    }
  }

  get hasDiscount() {
    if (this.hasOwnProperty("promotion")) {
      return true
    } else {
      return false
    }
  }

  get hasParticipants() {
    if (this.participants_count > 0) {
      return true
    } else {
      return false
    }
  }

  get discountRepresentation() {
    switch (this.discountType) {
      case "percentage":
        return `${this.promotion.discount}%`
        break;
      case "money":
        return toCurrency(this.promotion.discount)
        break;
      default:
        return 0;
    }
  }

  get priceDiscount() {
    switch (this.discountType) {
      case "percentage":
        return (this.promotion.discount / 100) *  this.price
        break;
      case "money":
        return this.promotion.discount
        break;
      default:
        return 0;
    }
  }

  get priceDiscountCurrency() {
    return toCurrency(this.priceDiscount)
  }

  get discountType() {
    if (this.hasDiscount) {
      return this.promotion.discount_type
    } else {
      return "none"
    }
  }

  get promotionId() {
    if (this.hasDiscount) {
      return this.promotion.id
    } else {
      return ""
    }
  }

  get taxPrice() {
    if (this.priceWithDiscount > 0 &&  this.priceWithDiscount <= 20) {
      return 2
    } else {
      return this.priceWithDiscount * this.eventTax
    }
  }

  get taxPriceCurrency() {
    return toCurrency(this.taxPrice)
  }
}
