<template lang="html">
  <Loading :isLoading="isLoading" :classes="{'-loading': isLoading}">
    <div v-if="!isLoading" class="root">
      <div class="enrollment-section">
        <div class="enrollment-container">
          <div class="enrollment-content -tickets">
            <h2 class="title">Informações dos participantes</h2>
            <div class="ticket-list">
              <Ticket
                v-for="(cartTicket, index) in cart.cart_tickets_attributes"
                :ticket="cartTicket"
                :tIndex="index"
                :$v="$v"
                :key="index"
                :ticketTitle="ticketTitle">
              </Ticket>
            </div>
          </div>
        </div>
      </div>

      <div class="enrollment-section">
        <div class="enrollment-container">
          <div class="enrollment-content -payment">
            <h2 class="title" v-if="cart.isCharged">
              Informações sobre pagamento
            </h2>

            <img
              v-if="cart.isCharged"
              :src="require('images/pagseguro.gif')"
              alt="PagSeguro"
              class="pagseguro-header is-hidden-mobile">

            <div class="payment-form" v-if="cart.isCharged">
              <Payment
                :pagseguroSession="pagseguro_session"
                :paymentType="paymentType"
                :cart="cart"
                :creditCard="creditCard"
                :hasBoleto="hasBoleto"
                :$v="$v">
              </Payment>

              <img
                v-if="cart.isCharged"
                :src="require('images/pagseguro.gif')"
                alt="PagSeguro"
                class="pagseguro-bottom is-hidden-tablet">
            </div>

            <div class="button-container" :class="{'-is-free': !cart.isCharged}">
              <div class="control save-data" v-if="cart.isCharged && !cart.payment_info.isPix">
                <label class="pl-checkbox">
                  <input type="checkbox" class="pl-checkbox-input" v-model="cart.save_data">
                  <span class="pl-checkbox-label">
                    Lembrar dos meus dados para compras mais rápidas.
                    <span class="pl-tooltip-help tooltip is-tooltip-multiline" :data-tooltip="tooltipText">
                      <span class="pl-tooltip-text">?</span>
                    </span>
                  </span>
                </label>
              </div>

              <a :href="back_url" class="back">VOLTAR</a>

              <ERButton
                :cartTotal="cart.priceTotalCurrency"
                :buttonDisabled="buttonDisabled"
                :isFetching="isFetching"
                :createCart="handleSubmit">
              </ERButton>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Loading>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import { isEmpty, countBy, cloneDeep } from 'lodash'
  import { toCurrency } from 'utils/currency'
  import { createCardToken } from 'src/Utils/PagSeguro'

  import Cart from 'src/Models/Carts/Model'
  import PaymentModel from 'src/Models/Payments/Model'
  import { validator as pValidator } from 'src/Models/Participants/Validator'

  import { required, maxLength } from 'vuelidate/lib/validators'
  import isCPForCNPJ from 'src/Validators/isCPForCNPJ'
  import minWord from 'src/Validators/minWord'
  import isDate from 'src/Validators/isDate'

  import Loading from 'src/Shared/Loading'
  import Ticket from './Tickets/Ticket'
  import Payment from './Payments/Payment'
  import ERButton from './Container/Button'

  export default {
    name: 'enrollment',


    data() {
      return {
        isLoading: true,
        leadIsValid: false,
        paymentIsValid: false,
        tooltipText: "Para facilitar as suas próximas compras, serão salvos dados como endereço, telefone, CPF/CNPJ e data de nascimento. Os dados do cartão de crédito não serão salvos.",

        creditCard: {
          number: '',
          brand: '',
          cvv: '',
          date: '',
        },

        paymentType: {
          creditCard: true,
          boleto: false,
          debitOnline: false,
          pix: false
        },

        cart: { },
      }
    },


    props: {
      cart_id: {
        type: String,
        required: true
      },

      back_url: {
        type: String,
        required: true
      },

      pagseguro_session: {
        type: String,
        required: true
      },

      payment_slip: {
        type: String,
        required: true,
        default: true
      },

      user_purchase: {
        type: String,
        required: true
      },

      user_data: {
        type: String,
        required: true
      },

      ticketTitle: {
        type: String,
        required: true
      },
    },


    mounted() {
      this.handleFetchCart()
    },


    components: {
      Loading,
      Ticket,
      Payment,
      ERButton
    },


    validations() {
      const listValidator =
        this.participant.map(participant => {
          return Object.keys(participant).reduce((map, key) => {
            map[key] = pValidator(participant[key])
            return map
          }, {})
        })

      if (this.cart.isCharged) {
        if (this.cart.payment_info.isCreditCard) {
          // validWhenCreditCard

          return {
            creditCard: {
              number: { required },
              brand: { required },
              cvv: { required },
              date: { required, isDate },
            },

            participant:  {...listValidator},

            cart: {
              payment_info: {
                installment: { required },
                type: { required }
              },

              holder: {
                name: { required, minWord: minWord(2) },
                born: { required },
                phone: { required },
                cpf: { required, isCPF: isCPForCNPJ },
              },

              address: {
                street: { required },
                number: { required },
                neighborhood: { required },
                zip_code: { required },
                state: { required },
                city: { required }
              },

              cart_tickets_attributes: {
                required,
                maxLength: maxLength(1000),
              }
            }
          }
        } else if (this.cart.payment_info.isBoleto && this.hasBoleto) {
          // validWhenBoleto

          return {
            participant:  {...listValidator},

            cart: {
              payment_info: {
                type: { required }
              },

              holder: {
                name: { required, minWord: minWord(2) },
                born: { },
                phone: { required },
                cpf: { required, isCPF: isCPForCNPJ },
              },

              address: {
                street: { required },
                number: { required },
                neighborhood: { required },
                zip_code: { required },
                state: { required },
                city: { required }
              },

              cart_tickets_attributes: {
                required,
                maxLength: maxLength(1000),
              }
            }
          }
        } else if (this.cart.payment_info.isDebitOnline) {
          // validWhenDebitOnline

          return {
            participant:  {...listValidator},

            cart: {
              payment_info: {
                type: { required },
                bank: { required }
              },

              holder: {
                name: { required, minWord: minWord(2) },
                born: { },
                phone: { required },
                cpf: { required, isCPF: isCPForCNPJ },
              },

              address: {
                street: { required },
                number: { required },
                neighborhood: { required },
                zip_code: { required },
                state: { required },
                city: { required }
              },

              cart_tickets_attributes: {
                required,
                maxLength: maxLength(1000),
              }
            }
          }
        } else if (this.cart.payment_info.isPix) {
          // validWhenPix

          return {
            participant:  {...listValidator},

            cart: {
              holder: {
                name: { required, minWord: minWord(2) },
                cpf: { required, isCPF: isCPForCNPJ },
              },

              address: {
                street: { },
                number: { },
                neighborhood: { },
                zip_code: { },
                state: { },
                city: { }
              },

              cart_tickets_attributes: {
                required,
                maxLength: maxLength(1000),
              }
            }
          }
        }
      } else {
        // validWhenZero

        return {
          participant:  {...listValidator},

          cart: {
            cart_tickets_attributes: {
              required,
              maxLength: maxLength(1000),
            }
          }
        }
      }
    },


    computed: {
      ...mapState({
        boleto: state => state.paymentMethods.boleto
      }),

      ...mapGetters([
        'isDisabled',
        'isFetching',
      ]),

      buttonDisabled: function() {
        if (this.$v.participant && (!this.$v.participant.$invalid && !this.leadIsValid)) {
          if (window.fbq) { window.fbq('track', 'Lead') }

          this.leadIsValid = true
        }

        if (this.$v.creditCard && (!this.$v.creditCard.$invalid && !this.paymentIsValid)) {
          if (window.fbq) { window.fbq('track', 'AddPaymentInfo') }

          this.paymentIsValid = true
        }

        return this.$v.$invalid || this.isFetching
      },

      hasBoleto: function() {
        return JSON.parse(this.payment_slip) && this.boleto.status == 'AVAILABLE'
      },

      participant: function() {
        return this.cart.cart_tickets_attributes.map((list) => {
          return list.participant.reduce((map, listElement) => {
            map[listElement.token] = listElement
            return map
          }, {})
        })
      }
    },


    methods: {
      ...mapActions([
        'setFetching',
        'setMessage',
        'resetMessages',
        'fetchCart',
        'updateCart',
      ]),

      handleSubmit() {
        if (this.$v.$invalid) {
          this.$v.$touch()
          this.setMessage({
            type: 'warning',
            message: 'Ops! Ocorreu algum erro no processamento do pagamento. Verifique o preenchimento dos seus dados e tente novamente.'
          })
        } else {
          if (this.cart.isCharged) {
            this.cart.payment_info.sender_hash = PagSeguroDirectPayment.getSenderHash()
            if (this.cart.payment_info.isCreditCard) {
              this.handleSubmitCreditCard().then(() => this.processPurchase())
            } else {
              this.processPurchase()
            }
          } else {
            this.processPurchase()
          }
        }
      },

      handleSubmitCreditCard() {
        this.setFetching({ fetching: true })
        return createCardToken(this.creditCard)
          .then(data => {
            this.cart.payment_info.credit_card_token = data.card.token
            return Promise.resolve(data)
          })
          .catch(data => {
            console.warn('createCardToken', data)
            this.setFetching({ fetching: false })
            this.setMessage({
              type: 'warning',
              message: 'Ops! Ocorreu algum erro no processamento do pagamento. Verifique o preenchimento dos seus dados e tente novamente.'
            })

            return Promise.reject(data)
          })
      },

      processPurchase() {
        const { cart } = this
        this.setFetching({ fetching: true })
        this.updateCart({
          cartId: cart.id,
          payload: {
            cart: cart,
            tracker_data: {
              source_url: window.location.href,
              user_agent: window.navigator.userAgent,
              fbp: this.getCookie('_fbp'),
              fbc: this.getCookie('_fbc')
            }
          }
        }).then((data) => {
          this.resetMessages()
          Turbolinks.visit(`/eventos/inscricao/sucesso?id=${data.id}`)
        })
        .catch((error) => {
          console.warn('processPurchase', error)
          this.setFetching({ fetching: false })
        })
      },

      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },

      handleFetchCart() {
        const userPurchase = JSON.parse(this.user_purchase)

        this.setFetching({ fetching: true })
        this.fetchCart({
          cartId: this.cart_id
        }).then(data => {
          this.isLoading = false
          this.cart = new Cart({
            id: data.id,
            event_id: data.event_id,
            eventTax: data.tax_online,
            customerFee: data.customer_fee,
            holder: userPurchase.holder,
            address: userPurchase.address || {},
            payment_info: new PaymentModel(data.total),
            cart_tickets_attributes: data.cart_tickets_attributes,
          })
          this.setDataFirstParticipant()
          this.setFetching({ fetching: false })
        })
        .catch(error => {
          console.warn(error)
          Turbolinks.visit(this.back_url)
        })
      },

      setDataFirstParticipant() {
        const userData = JSON.parse(this.user_data)
        const [head, tail] = this.cart.cart_tickets_attributes

        head.participant.map(data => {
          if (data.label == 'name') { data.value = userData.name }
          if (data.label == 'email') { data.value = userData.email }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pagseguro-header {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .pagseguro-bottom {
    display: block;
    margin: 0 auto;
    margin-top: 2rem;
  }
</style>
